import { ApiClient } from "./api"
import { FileUploadType } from "./api_types"

export const uploadFile = async (
  apiClient: ApiClient,
  file: File,
  fileUploadType: FileUploadType,
  entityId: string
) => {
  const uploadUrlRes = await apiClient.createUploadUrl({
    file_extension: file.type.split("/").pop() || "",
    file_upload_type: fileUploadType,
    entity_id: entityId,
  })

  if (!!uploadUrlRes.error || !uploadUrlRes?.data?.upload_url) {
    console.log("issue creating upload url", uploadUrlRes.error)
    return
  }
  console.log(uploadUrlRes.data.upload_url)

  const useUploadUrlRes = await apiClient.useUploadUrl(
    uploadUrlRes.data?.upload_url,
    file
  )

  if (!!useUploadUrlRes.error || !useUploadUrlRes.data) {
    console.log("error uploading file to S3", useUploadUrlRes.error)
    return
  }

  return `${uploadUrlRes.data.upload_url.url}${uploadUrlRes.data.upload_url.fields.key}`
}
